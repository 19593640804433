import { AutoComplete, AutoCompleteProps, Input, InputNumber, InputNumberProps, InputProps, Space } from 'antd'
import { TextAreaProps } from 'antd/lib/input';
import React from 'react'

type PropsType = InputProps | InputNumberProps | TextAreaProps | AutoCompleteProps
type ResetInputProps = {
    onChange: (value: any) => void;
    value: any;
    def?: any; // default value
    type?: 'number' | 'text' | 'textarea' | 'autocomplete';
    fullWidth?: boolean;
    wrapperProps?: React.HTMLAttributes<HTMLDivElement>;
    style?: React.CSSProperties;
    inputProps?: PropsType
    options?: { label: string, value: any }[]
    size?: 'small' | 'middle' | 'large'
}

export default function ResetInput({
    onChange,
    value,
    def,
    type = 'text',
    fullWidth: signupInput,
    wrapperProps,
    style,
    options,
    ...props
}: ResetInputProps) {
    // is not default value or is not original styled value
    const isNotDefault = (def && def !== 'custom' && value !== def) || (def === undefined && !!value);

    const handleReset = () => onChange(def);
    // Delete any props that are not valid for the input type
    const cleanProps = (props: any) => {
        const clean = Object.assign({}, props)
        delete clean.def
        delete clean.type
        delete clean.fullWidth
        delete clean.wrapperProps
        delete clean.value
        delete clean.style
        delete clean.onChange
        return clean
    }

    const inputProps: InputProps | InputNumberProps | TextAreaProps = {
        ...props.inputProps,
        ...cleanProps(props)
    }

    return (
        <div className='reset-input' style={{ display: 'flex', alignItems: 'center' }} {...wrapperProps}>
            {signupInput && (
                <div style={{ flex: 1, marginRight: 8 }}>
                    {type === 'autocomplete' ? (
                        <AutoComplete {...{ value, onChange, ...(inputProps as AutoCompleteProps), style }} />
                    ) : type === 'textarea' ? (
                        <Input.TextArea {...{ value, onChange, ...(inputProps as TextAreaProps), style }} />
                    ) : type === 'number' ? (
                        <InputNumber {...{ value, onChange, ...(inputProps as InputNumberProps), style }} />
                    ) : (
                        <Input {...{ value, onChange, ...(inputProps as InputProps), style }} />
                    )}
                </div>
            )}
            {!signupInput && (
                <div>
                    {type === 'autocomplete' ? (
                        <AutoComplete {...{ value, onChange, ...(inputProps as AutoCompleteProps), style }} />
                    ) : type === 'textarea' ? (
                        <Input.TextArea {...{ value, onChange, ...(inputProps as TextAreaProps), style }} />
                    ) : type === 'number' ? (
                        <InputNumber {...{ value, onChange, ...(inputProps as InputNumberProps), style }} />
                    ) : (
                        <Input {...{ value, onChange, ...(inputProps as InputProps), style }} />
                    )}
                </div>
            )}
            {isNotDefault && (
                <i
                    className="fa-regular fa-arrow-rotate-left linked"
                    style={{ marginLeft: 8 }}
                    onClick={handleReset}
                />
            )}
        </div>
    );
}